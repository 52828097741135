import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { useForm } from "react-hook-form";
import PageWrapper from "../components/PageWrapper";
import LastSeen from "../components/LastSeen";
import Skeleton from "react-loading-skeleton";

import imgF from "../assets/image/svg/icon-fire-rounded.svg";
import briefcase from "../assets/image/svg/icon-briefcase.svg";
import iconL from "../assets/image/svg/icon-loaction-pin-black.svg";
import iconS from "../assets/image/svg/icon-suitecase.svg";
import iconC from "../assets/image/svg/icon-clock.svg";
import { DataContext } from "../context/DataContext";
import { AuthContext } from "../context/AuthContext";
import GlobalContext from "../context/GlobalContext";
import notify from "../utils/notify";
import { validation } from "../utils/validation";
import { login } from "../utils/utilities";
import { translateThumbs } from "react-range/lib/utils";

const SearchList = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { register, handleSubmit, watch } = useForm();
  const { jobs, getAllJobsList, placeBid } = useContext(DataContext);
  const { auth, getUser, isAuthenticated } = useContext(AuthContext);
  const gContext = useContext(GlobalContext);
  const params = new URLSearchParams(location.search);
  const watchKeyword = watch("keyword");
  let accountId = params.get("account_id");
  let publicKey = params.get("public_key");

  const currentDate = new Date();

  const fetchJobs = async () => {
    const response = await getAllJobsList();
    const filteredjobs = response.filter(
      (job) =>
        job.status !== "expired"
    );

    const expiryDateFilter = filteredjobs.filter((job) => {
      if (job.expiryDate) {
        return new Date(job.expiryDate) >= currentDate
      } else {
        return true
      }
    });

    setSearchResults(expiryDateFilter);
    setLoading(false);
  };

  const fetchUserData = async (accountId) => {
    await isAuthenticated();
    await getUser(accountId);
  };

  useEffect(() => {
    if (accountId && publicKey) {
      localStorage.setItem("account_id", accountId);
      localStorage.setItem("public_key", publicKey);
      fetchUserData(accountId);
    }
    fetchJobs();
    return () => setLoading(true);
  }, []);

  const handlePlaceBid = async (jobId) => {
    if (auth && auth.talent) {
      setSubmitting(true);
      try {
        const data = {
          account_id: auth.talent.account_id,
          firstname: auth.talent.firstname,
          created: new Date(),
        };
        const response = await placeBid(jobId, "application", data);
        if (response === true) {
          notify("Bid sent successfuly", "success");
          setSubmitting(false);
          navigate(`/job-details/${jobId}`, { state: { id: jobId } });
        } else {
          notify(response.message);
          setSubmitting(false);
        }
      } catch (error) {
        notify(error.message);
        setSubmitting(false);
      }
    } else if (auth && !auth.talent) {
      notify("Only talents can apply to jobs");
    } else if (!auth) {
      await login(location.pathname, location.pathname);
    } else {
      notify("Login as a Talent to apply to this job");
    }
  };

  const handleSearchInput = async (data) => {
    const searchKeyword = data.toLocaleLowerCase();
    const response = jobs.filter(
      (job) =>
        job.position.toLocaleLowerCase().includes(searchKeyword) ||
        job.skills
          .toString()
          .toLocaleLowerCase()
          .includes(searchKeyword) ||
        job.category.toLocaleLowerCase().includes(searchKeyword) ||
        job.subcategory.toLocaleLowerCase().includes(searchKeyword) ||
        job.otherCategory?.toLocaleLowerCase().includes(searchKeyword) ||
        job.location.toLocaleLowerCase() === searchKeyword &&
        job.status !== "expired"
    );
    const expiryDateFilter = response.filter((job) => {
      if (job.expiryDate) {
        return job.expiryDate.toDate() >= currentDate
      } else {
        return true
      }
    });

    setSearchResults(expiryDateFilter);
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const { keyword } = data;
      const searchKeyword = keyword.toLocaleLowerCase();
      const response = jobs.filter(
        (job) =>
          job.position.toLocaleLowerCase().includes(searchKeyword) ||
          job.skills
            .toString()
            .toLocaleLowerCase()
            .includes(searchKeyword) ||
          job.category.toLocaleLowerCase().includes(searchKeyword) ||
          job.subcategory.toLocaleLowerCase().includes(searchKeyword) ||
          job.otherCategory?.toLocaleLowerCase().includes(searchKeyword) ||
          job.location.toLocaleLowerCase() === searchKeyword &&
          job.status !== "expired"
      );
      const expiryDateFilter = response.filter((job) => {
        if (job.expiryDate) {
          return job.expiryDate.toDate() >= currentDate
        } else {
          return true
        }
      });

      setSearchResults(expiryDateFilter);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <PageWrapper>
        <div className="bg-default-1 pt-26 pt-lg-28 pb-13 pb-lg-25">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-xl-8 col-lg-8">
                <form
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                  className="search-form"
                >
                  <div className="filter-search-form-2 search-1-adjustment bg-white rounded-sm shadow-7 pr-6 py-6 pl-6">
                    <div className="filter-inputs">
                      <div className="form-group position-relative w-100">
                        <input
                          className="form-control focus-reset pl-13"
                          type="text"
                          id="keyword"
                          name="keyword"
                          placeholder="Job title, skill, or location"
                          ref={register(validation.search)}
                          onChange={(e) => handleSearchInput(e.target.value)}
                        />
                        <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                          <i className="icon icon-zoom-2 text-primary font-weight-bold"></i>
                        </span>
                      </div>
                    </div>
                    <div className="button-block">
                      <button
                        type="submit"
                        className="btn btn-primary line-height-reset h-100 btn-submit w-100 text-uppercase focus-reset"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </form>
                <div className="pt-12">
                  <div className="d-flex align-items-center justify-content-between mb-6">
                    <h5 className="font-size-4 font-weight-normal text-gray">
                      <span className="heading-default-color mr-1">
                        {searchResults
                          ? searchResults.filter(({ status }) =>
                            ["pending", "read"].includes(status)
                          ).length
                          : 0}
                      </span>
                      results for{" "}
                      <span className="heading-default-color">
                        {watchKeyword || "All Jobs"}
                      </span>
                    </h5>
                    <div className="d-flex align-items-center result-view-type">
                      <Link
                        to="/search-list"
                        className="heading-default-color pl-5 font-size-6 hover-text-hitgray active"
                      >
                        <i className="fa fa-list-ul"></i>
                      </Link>
                    </div>
                  </div>
                  {loading ? (
                    <div className="mb-8">
                      <div className="pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3 ">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="media align-items-center">
                              <div className="square-72 d-block mr-8">
                                <Skeleton width={72} height={72} />
                              </div>
                              <div>
                                <h3 className="mb-0">
                                  <Skeleton width={200} height={42} />
                                </h3>
                                <Skeleton width={150} height={24} />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 text-right pt-7 pt-md-5">
                            <div className="media justify-content-md-end">
                              <Skeleton width={100} height={42} />
                            </div>
                          </div>
                        </div>
                        <div className="row pt-8">
                          <div className="col-md-7">
                            <Skeleton width={250} height={24} />
                          </div>
                          <div className="col-md-5">
                            <ul className="d-flex list-unstyled mr-n3 flex-wrap mr-n8 justify-content-md-end">
                              <li className="mt-2 mr-8 font-size-small text-black-2 d-flex">
                                <Skeleton width={80} height={16} />
                              </li>
                              <li className="mt-2 mr-8 font-size-small text-black-2 d-flex">
                                <Skeleton width={80} height={16} />
                              </li>
                              <li className="mt-2 mr-8 font-size-small text-black-2 d-flex">
                                <Skeleton width={80} height={16} />
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row justify-content-end">
                          <div className="col-6 text-left align-items-center">
                            <Skeleton width={130} height={26} />
                          </div>
                          <div className="col-6 text-right align-items-center">
                            <Skeleton width={130} height={40} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : searchResults ? (
                    searchResults.map((job, index) =>
                      job.status === "pending" || job.status === "read" ? (
                        <div key={index} className="mb-8">
                          <div
                            className={`pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3 ${job?.featured ? "border border-primary" : null
                              }`}
                          >
                            <div className="row">
                              <div className="col-md-8">
                                <div className="media align-items-center">
                                  <div className="square-72 d-block mr-8 rounded">
                                    {job?.logo ? (
                                      <img
                                        src={job.logo}
                                        alt={`${job?.company} logo`}
                                        height={72}
                                        width={72}
                                      />
                                    ) : (
                                      <img
                                        src={briefcase}
                                        alt="Company logo placeholder"
                                        height={72}
                                        width={72}
                                      />
                                    )}
                                  </div>
                                  <div>
                                    <h3 className="mb-0">
                                      <Link
                                        to={`/job-details/${job.id}`}
                                        state={{ id: job.id }}
                                        className="font-size-6 heading-default-color"
                                      >
                                        {job.position}
                                      </Link>
                                    </h3>
                                    <p className="font-size-3 text-default-color line-height-2">
                                      {job?.company}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 text-right pt-7 pt-md-5">
                                <div className="media justify-content-md-end">
                                  {job?.featured ? (
                                    <div className="image mr-5 mt-1">
                                      <img src={imgF} alt="" />
                                    </div>
                                  ) : null}
                                  <p className="font-weight-bold font-size-7 text-hit-gray mb-0">
                                    <span className="text-black-2">
                                      {job?.rate
                                        ? job.rate
                                          .toString()
                                          .replace(
                                            /(\d)(?=(\d{3})+(?!\d))/g,
                                            "$1,"
                                          )
                                        : job?.salary
                                          ? job?.salary
                                            .toString()
                                            .replace(
                                              /(\d)(?=(\d{3})+(?!\d))/g,
                                              "$1,"
                                            )
                                          : null}
                                    </span>{" "}
                                    {job?.salary === 0
                                      ? null
                                      : job?.rate === 0
                                        ? null
                                        : job?.currency}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="row pt-8">
                              <div className="col-md-6">
                                <ul className="d-flex list-unstyled mr-n3 flex-wrap">
                                  {job?.skills &&
                                    job.skills.map((skill, index) => (
                                      <li key={index}>
                                        <Link
                                          to="/#"
                                          onClick={(e) => e.preventDefault()}
                                          className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
                                        >
                                          {skill}
                                        </Link>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                              <div className="col-md-6">
                                <ul className="d-flex list-unstyled mr-n3 flex-wrap mr-n8 justify-content-md-end">
                                  <li className="mt-2 mr-8 font-size-small text-black-2 d-flex">
                                    <span
                                      className="mr-4"
                                      css={`
                                        margin-top: -2px;
                                      `}
                                    >
                                      <img src={iconL} alt="" />
                                    </span>
                                    <span className="font-weight-semibold">
                                      {job.location}
                                    </span>
                                  </li>
                                  <li className="mt-2 mr-8 font-size-small text-black-2 d-flex">
                                    <span
                                      className="mr-4"
                                      css={`
                                        margin-top: -2px;
                                      `}
                                    >
                                      <img src={iconS} alt="" />
                                    </span>
                                    <span className="font-weight-semibold">
                                      {job?.jobtype}
                                    </span>
                                  </li>
                                  <li className="mt-2 mr-8 font-size-small text-black-2 d-flex">
                                    <span
                                      className="mr-4"
                                      css={`
                                        margin-top: -2px;
                                      `}
                                    >
                                      <img src={iconC} alt="" />
                                    </span>
                                    <span className="font-weight-semibold">
                                      <LastSeen
                                        date={new Date(job?.created.toDate())}
                                      />
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="row justify-content-end">
                              <div className="col-8 text-right align-items-center">
                                {auth &&
                                  auth?.client ? null : job?.applicationurl ? (
                                    <a
                                      href={job.applicationurl}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="font-size-3 font-weight-bold text-green text-uppercase mr-6"
                                    >
                                      <span className="mr-2">
                                        Apply to this job
                                      </span>
                                      <i className="fa fa-external-link-alt"></i>
                                    </a>
                                  ) : (
                                  <button
                                    className={`btn btn-${gContext.header.variant} text-uppercase font-size-3 focus-reset`}
                                    type="link"
                                    disabled={
                                      submitting ||
                                        (job?.bids &&
                                          job?.bids.find(
                                            ({ account_id }) =>
                                              account_id ===
                                              auth?.talent?.account_id
                                          ))
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handlePlaceBid(job.id);
                                    }}
                                  >
                                    {submitting &&
                                      job?.bids &&
                                      !job?.bids.find(
                                        ({ account_id }) =>
                                          account_id === auth?.talent?.account_id
                                      )
                                      ? "Applying..."
                                      : job?.bids &&
                                        job?.bids.find(
                                          ({ account_id }) =>
                                            account_id ===
                                            auth?.talent?.account_id
                                        )
                                        ? "Applied"
                                        : "Apply Now"}
                                  </button>
                                )}
                                <Link
                                  to={`/job-details/${job.id}`}
                                  state={{ id: job.id }}
                                  className="btn btn-outline text-uppercase font-size-3 text-green focus-reset ml-4"
                                >
                                  Details
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default SearchList;
